.navigation-panel {
  width: 100%;
  height: 40px;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation-items-left,
.navigation-items-right {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  > div {
    margin: 0 0px 0 0;
  }
}

.navigation-items-right {
  > div {
    margin: 0 0 0 10px;
  }
}
