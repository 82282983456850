.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.box-detail {
  border: 1px solid #b1c5d9;
  border-radius: 8px;
  padding: 16px 20px;
}

.box-detail-f2 {
  /*font-family: 'Open Sans';*/
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 26px;
  color: #1a2c3d;
  vertical-align: middle;
  margin-left: 10px;
}
.css-py2brw {
  overflow: scroll;
}
.css-1zts0j {
  text-transform: none !important;
}
.input-style {
  background-color: #f2f2f2 !important;
  color: #1a2c3d !important;
  border: 1px solid #b1c5d9 !important;
  border-radius: 8px !important;
  height: 40px !important;
  width: 100% !important;
}
.open-sans {
  font-family: 'Open Sans';
}
.d-inline {
  display: inline;
}
.d-flex {
  display: flex;
}
.position-relative {
  position: relative;
}
.date-picker {
  background-color: #ffffff !important;
}

.date-picker.sm {
  height: 38px !important;
}

.cal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.cal-icon.sm {
  top: 7px;
}

.mt-10 {
  margin-top: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.box-detail-f1 {
  /*font-family: 'Open Sans';*/
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #5a738b;
}
.checkbox css-whqy0f[aria-checked='true'],
.checkbox .css-whqy0f[data-checked] {
  color: #1a2c3d !important;
}

.css-1mw3c5t[aria-selected='true'],
.css-1mw3c5t[data-selected] {
  border-color: #3343d4;
}

.css-1xhq01z {
  padding-left: 20px;
}

.p-f {
  padding: 50px;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 42px;
  background: #f2f2f2;
  padding-left: 10px;
  border: 1px solid #b1c5d9;
  border-radius: 8px;
  font-size: 14px;
}
.label-input {
  font-family: 'Open Sans';
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #5a738b;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.dashboard-summary-box {
  width: 275px;
  height: 80px;
  border: 1px solid #b1c5d9;
  padding: 10px;
  border-radius: 10px;
  font-size: 12px;
}

.dashboard-summary-box-value {
  font-weight: bold;
  font-size: 30px;
  margin-left: -80px;
  margin-top: 5px;
}

.dashboard-summary-box-img {
  width: 30px;
  height: 30px;
  margin-top: 5px;
}

.app-select {
  white-space: nowrap;
}
.customStyle .app-select__indicators {
  height: 38px;
}

.right-padding {
  padding-right: 40px !important;
}

.followup-details-bold label {
  color: #2a244d;
  font-weight: 600;
}

.app-select__control {
  font-size: 13.5px;
}



input[type="checkbox"].select-task-check {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  background-color: white; /* White background for the checkbox */
  border-radius: 4px; /* Optional: add some border radius */
  position: relative;
  cursor: pointer;
}

/* Style the checkmark */
input[type="checkbox"].select-task-check:checked::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 5px;
  width: 7px;
  height: 12px;
  border: solid #8A2BE2;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}


.show-selected span{
  font-size: 14px;
  box-shadow: none !important;
  outline: none !important;
}
.show-selected .chakra-checkbox__control[data-checked] {
 background:  #3443D5;
 border-color: #3443D5
}

.release-root-wrapper > div {
  background-color: white;
}


.print-only {
  display: none;
}

@media print {
  .print-only {
    display: block !important;
  }
  .checkbox-print{
      width: 100% !important;
      border: 1px solid white !important;
      background: none !important;
      box-shadow: none !important;
      color: black !important;
      padding: 10px !important;
  }
  .checkbox-circle-print {
    width: 25px !important;
    height: 25px !important;
    border: 1px solid white !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50% !important;
  }

  .checkbox-circle-print svg {
    fill: #FF5099 !important;
  }

  .task-page {
    page-break-after: always;
    break-after: page;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
  }
  .glass-bowl{
    height: 200px;
  }

  .print-cards{
    width: 18vh !important;
    height: 18vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .print-flex{
    justify-content: space-evenly;
  }

}