.pagination-container {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  // border: 1px solid #cdcfdf;
  border-top: none;
  padding: 0 10px;

  .pagination-description {
    color: #8c9eaf;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination-control {
    list-style-type: none;
    display: flex;
  }

  .pagination-element {
    box-sizing: border-box;
    padding: 0 12px;
    height: 32px;
    margin: auto 4px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    font-size: 13px;
    min-width: 32px;
    line-height: 1.43;
  }

  .pagination-arrow {
    background-color: #f4f7fd;
    color: #375385;
    border: none;
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }

    &.disabled {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.43);
    }
  }

  .pagination-item {
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.01071em;
    border: 1px solid #cdcfdf;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: #375385;
      color: white;
    }

    &.disabled {
      pointer-events: none;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
