@import-normalize;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
  font-family: 'Open Sans';
}

#root {
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: 'Open Sans';
}

* {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}
